exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banners-competitions-js": () => import("./../../../src/pages/banners/competitions.js" /* webpackChunkName: "component---src-pages-banners-competitions-js" */),
  "component---src-pages-banners-events-event-id-js": () => import("./../../../src/pages/banners/events/[eventId].js" /* webpackChunkName: "component---src-pages-banners-events-event-id-js" */),
  "component---src-pages-banners-events-js": () => import("./../../../src/pages/banners/events.js" /* webpackChunkName: "component---src-pages-banners-events-js" */),
  "component---src-pages-banners-js": () => import("./../../../src/pages/banners.js" /* webpackChunkName: "component---src-pages-banners-js" */),
  "component---src-pages-banners-sports-js": () => import("./../../../src/pages/banners/sports.js" /* webpackChunkName: "component---src-pages-banners-sports-js" */),
  "component---src-pages-banners-sports-sport-id-competitions-competition-id-js": () => import("./../../../src/pages/banners/sports/[sportId]/competitions/[competitionId].js" /* webpackChunkName: "component---src-pages-banners-sports-sport-id-competitions-competition-id-js" */),
  "component---src-pages-banners-sports-sport-id-js": () => import("./../../../src/pages/banners/sports/[sportId].js" /* webpackChunkName: "component---src-pages-banners-sports-sport-id-js" */),
  "component---src-pages-banners-sports-sport-id-teams-team-id-js": () => import("./../../../src/pages/banners/sports/[sportId]/teams/[teamId].js" /* webpackChunkName: "component---src-pages-banners-sports-sport-id-teams-team-id-js" */),
  "component---src-pages-banners-teams-js": () => import("./../../../src/pages/banners/teams.js" /* webpackChunkName: "component---src-pages-banners-teams-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

